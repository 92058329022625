export function useFindAndUpdate<T extends Record<string, any>>(
  search: MaybeRef<Array<Record<string, any>>>,
  key: keyof T,
  replacement: T,
) {
  if (isRef(search)) {
    const idx = useFindIndex(search.value, [key, replacement[key]])
    if (idx !== -1)
      search.value.splice(idx, 1, replacement)
  }
  else {
    const idx = useFindIndex(search, [key, replacement[key]])
    if (idx !== -1)
      search.splice(idx, 1, replacement)
  }
}

export function useFindAndDelete<T extends Record<string, any>>(
  search: MaybeRef<Array<Record<string, any>>>,
  key: keyof T,
  replacement: T,
) {
  if (isRef(search)) {
    const idx = useFindIndex(search.value, [key, replacement[key]])
    if (idx !== -1)
      search.value.splice(idx, 1)
  }
  else {
    const idx = useFindIndex(search, [key, replacement[key]])
    if (idx !== -1)
      search.splice(idx, 1)
  }
}
