import { defineStore } from 'pinia'
import { Pagination } from '@packages/ui/helpers/pagination'
import type { FilteredParams, SearchableParams, SortableParams } from '~/repository/factory'
import type { CaseFilterData, CaseStepProcessingData, CaseSummary, PlaceHoldCases, WorkflowStep } from '~/repository/modules'

export const useCasesStore = defineStore('cases', () => {
  const { $api, $toast } = useNuxtApp()

  const filterData = ref<CaseFilterData>()
  const cases = ref<CaseSummary[]>([])
  const caseErrors = ref<{ [caseId: string]: string[] }>({})
  const casesLocalChannel = new BroadcastChannel('case-updates')
  const pagination = ref<Pagination>(new Pagination({ per_page: 50 }))
  const filters = reactive<SearchableParams & SortableParams & FilteredParams>({
    sort: undefined,
    search: undefined,
    filters: {},
  })
  const loading = ref<boolean>(false)

  const listFilters = async (workflowStepId: WorkflowStep['id']) => {
    const { data } = await $api.cases.filters(workflowStepId)
    filterData.value = data
  }

  const listProcessingFilters = async (onHold: boolean, stepId: string | null = null) => {
    const { data } = await $api.cases.processingFilters(onHold, stepId)
    filterData.value = data
  }

  const list = async (workflowStepId: WorkflowStep['id']) => {
    loading.value = true
    const response = await $api.cases.list(workflowStepId, {
      page: pagination.value.current_page,
      per_page: pagination.value.per_page,
      ...filters,
      where: [
        {
          column: 'processing_requested',
          operator: '=',
          value: 0,
        },
      ],
    })
    cases.value = response.data
    pagination.value.update(response.meta)
    loading.value = false
  }

  const listForProcessing = async (onHold = false, stepId: string | null = null) => {
    loading.value = true

    const whereClause: Array<{ column: string; operator: string; value: unknown }> = [
      {
        column: 'hold_requested',
        operator: '=',
        value: onHold ? 1 : 0,
      },
    ]

    if (!onHold) {
      whereClause.push({
        column: 'processing_requested',
        operator: '=',
        value: 1,
      })
    }

    if (stepId) {
      whereClause.push({
        column: 'workflow_step_id',
        operator: '=',
        value: stepId,
      })
    }

    const response = await $api.cases.listAll({
      page: pagination.value.current_page,
      per_page: pagination.value.per_page,
      ...filters,
      where: whereClause,
    })
    cases.value = response.data
    pagination.value.update(response.meta)
    loading.value = false
  }

  const listAll = async () => {
    loading.value = true
    const response = await $api.cases.listAll({
      page: pagination.value.current_page,
      per_page: pagination.value.per_page,
      ...filters,
    })
    cases.value = response.data
    pagination.value.update(response.meta)
    loading.value = false
  }

  const processTransition = async (processingData: Array<CaseStepProcessingData>) => {
    loading.value = true
    await $api.cases.processTransition(processingData)
    $toast.addToast({
      title: 'Case Transition Processed',
    })
    loading.value = false
  }

  const challengeTransition = async (caseIds: Array<CaseSummary['id']>, note: string) => {
    if (!note)
      throw new Error('A note is required')

    loading.value = true
    await $api.cases.challengeTransition({ case_ids: caseIds, note })
    $toast.addToast({
      title: 'Case Transition Challenged',
    })
    loading.value = false
  }

  const challengeHold = async (caseIds: Array<CaseSummary['id']>, note: string) => {
    if (!note)
      throw new Error('A note is required')

    loading.value = true
    await $api.cases.challengeHold({ case_ids: caseIds, note } as PlaceHoldCases)
    $toast.addToast({
      title: 'Case Hold Challenged',
    })
    loading.value = false
  }

  const updateCaseLocally = (id: CaseSummary['id'], data: Partial<CaseSummary>) => {
    const _case = useFind(cases.value, { id })

    if (_case) {
      useFindAndUpdate(cases.value, 'id', {
        ..._case,
        ...data,
      })
    }
  }

  const removeCaseLocally = (id: CaseSummary['id']) => {
    const caseIndex = useFindIndex(cases.value, { id })

    if (caseIndex !== -1) {
      cases.value.splice(caseIndex, 1)
      pagination.value.total--
    }
  }

  const disableCaseLocally = (id: CaseSummary['id']) => {
    const caseIndex = useFindIndex(cases.value, { id })

    if (caseIndex !== -1)
      cases.value[caseIndex].disabled = true
  }

  const enableCaseLocally = (id: CaseSummary['id']) => {
    const caseIndex = useFindIndex(cases.value, { id })

    if (caseIndex !== -1)
      cases.value[caseIndex].disabled = false
  }

  const handleCaseUpdate = (notification: any) => {
    switch (notification.name) {
      case 'case.moved':
        removeCaseLocally(notification.data?.case_id)
        break
      case 'case.processing':
        delete caseErrors.value[notification.data?.case_id]
        disableCaseLocally(notification.data?.case_id)
        break
      case 'case.unlock':
        if (notification.data?.errors)
          caseErrors.value[notification.data?.case_id] = notification.data?.errors

        enableCaseLocally(notification.data?.case_id)
        break
    }
  }

  casesLocalChannel.onmessage = (event) => {
    if (event.data.type === 'case-update')
      handleCaseUpdate(event.data.data)
  }

  $api.cases.courtDate.on(['created', 'updated'], (data) => {
    const caseIndex = useFindIndex(cases.value, { id: data.caseId })

    const updates = {} as Partial<CaseSummary>

    if (caseIndex !== -1) {
      if (data.courtDate.id)
        updates.court_date_id = data.courtDate.id

      if (data.courtDate.date)
        updates.court_date = data.courtDate.date

      if (data.courtDate.time)
        updates.court_time = data.courtDate.time

      cases.value[caseIndex] = { ...cases.value[caseIndex], ...updates }
    }
  })

  $api.cases.on('evictionNoticeGenerated', () => {
    $toast.addToast({
      title: 'Eviction Notice Jobs Dispatched!',
    })
  })

  return {
    // variables
    cases,
    caseErrors,
    casesLocalChannel,
    pagination,
    filters,
    loading,
    filterData,

    // methods
    processTransition,
    challengeTransition,
    challengeHold,
    list,
    listAll,
    listFilters,
    listForProcessing,
    listProcessingFilters,
    updateCaseLocally,
    removeCaseLocally,
    disableCaseLocally,
    enableCaseLocally,
    handleCaseUpdate,
  }
})
